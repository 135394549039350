
/**
 * 画像ファイルアップローダー
 */

$(function()
{
	/**
	 * 選択画面
	 */
	$('.img-uploader.img-uploader-editor input[type=file]').on('change', function(e){
		const $container = $(this).parents('.img-uploader').eq(0)
		const $a         = $container.find('.uploading a.preview')
		const $img       = $a.find('img')
		const reader     = new FileReader()
		reader.onload    = function (e) {
			$a.attr('href', e.target.result)
			$img.attr('src', e.target.result)
			$container.attr('data-mode', 'uploading')
		}
		if(e.target.files.length) {
			reader.readAsDataURL(e.target.files[0]);
		} else {
			$container.find('.cancel').trigger('click')
		}
	})

	/**
	 * 選択画面：取り消しボタン
	 */
	$('.img-uploader.img-uploader-editor .cancel').on('click', function(e){
		const $container = $(this).parents('.img-uploader').eq(0)
		const $input     = $container.find('input[type=file]')
		const $base64    = $container.find('input[name$=_base64]')
		$input.prop('value', null)
		$base64.prop('value', null)
		$container.attr('data-mode', $container.find('.uploaded').length ? 'uploaded' : '')
		$container.parents('td').eq(0).find('[data-validon-errorholder]').remove()
	})

	/**
	 * 選択画面：削除ボタン
	 */
	$('.img-uploader.img-uploader-editor .delete').on('change', function(e){
		const $container = $(this).parents('.img-uploader').eq(0)
		$container.toggleClass('delete', $(this).val())
	})

	/**
	 * 確認画面：img[src]やa[href]をinputの値からもってくる（転送量削減のため）
	 */
	$('[data-src-from]').each(function(){
		const $img = $(this);
		$img.attr('src', $('input[name='+$img.data('src-from')+']').val())
	})
	$('[data-href-from]').each(function(){
		const $img = $(this);
		$img.attr('href', $('input[name='+$img.data('href-from')+']').val())
	})
});
